import { ApiAxios } from './api.config';

const RESOURCE = '/admin';

export default {
  async login(user_email, user_password) {
    return await ApiAxios().post(`${RESOURCE}/login`, {
      admin_email: user_email,
      password: user_password
    });
  },

  async checkIsAdmin() {
    return await ApiAxios().get(`${RESOURCE}/me`);
  }
};
