import { ApiAxios } from './api.config';

const RESOURCE = '/users';

export default {
  async listUsers(limit, page, query = null) {
    return await ApiAxios().get(RESOURCE, {
      params: {
        limit,
        page,
        query
      }
    });
  },

  async getUserProfile(user_id) {
    return await ApiAxios().get(`${RESOURCE}/${user_id}`);
  },

  async cancelSubscription(user_id, type) {
    return await ApiAxios().put(`${RESOURCE}/${user_id}/cancel-account`, {
      cancel_type: type
    });
  }
};
