import axios from 'axios';

const getInstance = () => {
  const axiosApi = axios.create({
    baseURL: process.env.VUE_APP_API,
    timeout: 36000,
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token_session')}`
    }
  });
  axiosApi.interceptors.response.use(
    response => {
      return {
        ...response.data,
        isError: false
      };
    },
    error => {
      return {
        data: {
          status: error.response.status,
          error: error.response.data.message
        },
        isError: true
      };
    }
  );

  return axiosApi;
};

export const ApiAxios = () => getInstance();
